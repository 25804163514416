import TopImage from "../assets/images/about.jpg";
import '../assets/css/aboutpage.css';
import AboutUsImage from '../assets/images/abou-us.png'
import JoinImg from '../assets/images/about-l.jpg'

export const AboutUs = () => {
    return (
        <div className="about-us">
            <div className="top-img  position-relative">
                <img src={TopImage} alt="" />
                <h1 className="position-absolute start-50 top-50 translate-middle text-white">About us </h1>
            </div>
            <div className="container">
                <div className="about-upper py-4 text-center">
                    <h2 className="position-relative">About Us</h2>
                    <h3 className="mt-3">We are Not The Best, But We Can be Best for Your Digital Project</h3>

                    <p className="pt-3">At Hash Digitech, we are not just a digital marketing service business; we are your growth partners, your brand builder,and your technology supporters. We bring a world of digital possibilities to your doorstep, creating new and innovative solutions that matches with your audience and drive your business growth.
                    </p>
                </div>
            </div>
            <div className="about-us-sec why-choose py-5 bg-light">
            <div className="container">
            <h2 className="text-center">Why Choose Us?</h2>
                        <p>Strategic Creativity: We blend our ability to create interesting new things with our reliable insights to create a plan to reach the goal so thtat it does not only catch the eye but also deliver visible results. Our approach goes beyond beauty; it's about meaningful engagement and required conversions.</p>
                <div className="row">
                
                    <div className="col-md-12 ">
                       
                        <ul className="d-flex flex-wrap ps-0">
                            <li>
                                <h3>Tech-Driven Solutions:</h3>
                                <p>In the ever-changing digital world, we stay at the front lines of everyday advance technology. Our tech savvy team takes advantage of our latest and best tools and platforms to improve  your digital presence and continuously work towards increasing your brand's reach.</p>
                            </li>
                            <li>
                                <h3>Tailored Approach</h3>
                                <p>We understand that every brand is different and unique in this  world. That's why we don't believe in cookie-cutter solutions. Our success plans are customized as per your particular goals, making sure of a personalised and effective digital solution.</p>
                            </li>
                            <li>
                                <h3>Data-Powered Insights</h3>
                                <p>We believe in the power of data-driven decisions. Our amazing abilities of deep thinking uncovers valuable understandings of things that fuel optimization, enabling us to filtre out more pure ways of reaching goals and drive continuous improvement.</p>
                            </li>
                            <li>
                                <h3>Complete Projects</h3>
                                <p>With a portfolio bragging over 1000 successfully completed projects, we have a proven track record of turning ideas into impactful digital realities.</p>
                            </li>
                            <li>
                                <h3>Happy Clients</h3>
                                <p>Our focus on client satisfaction has empowered us with the trust of more than 500 happy clients, each with a unique success story to tell.</p>
                            </li>
                            <li>
                                <h3>Expert Team</h3>
                                <p>A powerhouse of abilities to create new things, our team of 20+ experts brings multiple range of skills to the table, securing that every project is a masterpiece in its own way.</p>
                            </li>
                            <li>
                                <h3>Experience</h3>
                                <p>With a total experience of over 10 years, we have improved our skills, welcomed industry trends, and purify our success plans to stay at the front lines of digital invention of new things. </p>
                            </li>
                            <li>
                                <h3>Our Mission</h3>
                                <p>Our mission is to empower businesses with amazing digital abilities, delivering new and innovative solutions that improve brand identity, engage audiences, and drive growth in the ever-changing digital view. </p>
                            </li>

                            <li>
                                <h3>Our commitment</h3>
                                <p>Our commitment extends beyond projects; it's a promise to be your digital partners on the road to success. We promise steady dedication, open and honest working team effort, and an unstoppable pursuit of excellence.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            </div>
            <div className="about-us-sec py-5">
            <div className="container">
                <div className="row  flex-row-reverse align-items-center">
                    <div className="col-md-6">
                        <img className="w-75 d-flex m-auto" src={AboutUsImage} alt="" />
                    </div>
                    <div className="col-md-6">
                        <h2 className="mb-3">Core Values</h2>
                       <ul className="ps-0">
                        <li>
                            <h3 className="mb-1">Innovation</h3>
                            <p>We grow and do well on pushing borders, bringing fresh opinions and best solutions to every challenge.</p>
                        </li>
                        <li>
                            <h3 className="mb-1">Integrity</h3>
                            <p>Honesty and transparency are the building blocks of our relationships - with clients, partners, and within our team.</p>
                        </li>
                        <li>
                            <h3 className="mb-1">Collaboration</h3>
                            <p>Together, we accomplish  more. We develop a collaborative environment where different talents merge to create magic.</p>
                        </li>
                        <li>
                            <h3 className="mb-1">Passion </h3>
                            <p>Our work is our passion. We pour our hearts into every project,making sure that the result reflects our dedication.</p>
                        </li>
                        <li>
                            <h3 className="mb-1">Results </h3>
                            <p>We're driven by results. Your success is our final goal, and we measure our achievements by the effect we create.</p>
                        </li>
                       </ul>
                    </div>
                    </div>
                </div>
                </div>
                <div className="about-us-sec py-5">
                <div className="container">

                <div className="row">
                    <div className="col-md-6">
                        <img className="w-75 d-flex m-auto" src={JoinImg} alt="" />
                    </div>
                    <div className="col-md-6">
                        <h2>Join Us in Redefining Digital</h2>
                        <p>
Embrace a journey of digital transformation with Hash Digitech. Let's connect, innovate, and create amazing digital experiences that leave a lasting impression.

Step into the world of digital possibilities with Hash Digitech - wherere creativity meets strategy, and dreams changes into reality.</p>
                    </div>
                    </div>
                </div>
                </div>

        </div>

    );
}