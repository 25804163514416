import Accordion from 'react-bootstrap/Accordion';
//  defaultActiveKey="0"
function Accordian() {
  return (
    <Accordion >
      <Accordion.Item eventKey="0">
        <Accordion.Header>What is Digital Marketing?</Accordion.Header>
        <Accordion.Body>
        Digital marketing refers to the use of digital channels, such as search engines, social media, email, youtube and mobile apps, to reach customers. It helps businesses to connect with customers, increase brand awareness, and boost sales through the use of various digital technologies. Digital marketing tactics include search engine optimization (SEO), content marketing, influencer marketing, email marketing, social media marketing, pay-per-click (PPC) advertising, affiliate marketing, and mobile app marketing. By utilizing these channels, businesses can reach a wider audience, personalize marketing efforts, and track the success of their campaigns in real-time.

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>What is SEO? How Does it Work?</Accordion.Header>
        <Accordion.Body>
        SEO stands for search engine optimization, which is the process of improving the ranking of a website on search engines like Google. When people search for a particular term on a search engine, the search engine tries to find the most relevant and authoritative websites to show in the search results. To do this, it uses algorithms to crawl and index the pages of the web, and then rank them based on a number of factors, including the quality and relevance of the content, the number and quality of links pointing to the page, and the user experience. By optimizing a website for search engines, businesses can improve their chances of ranking higher in the search results and getting more traffic to their site.


        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Why is Digital Marketing so Important?</Accordion.Header>
        <Accordion.Body>
        Digital marketing is important because it allows businesses to reach and engage with consumers where they spend most of their time: online. By utilizing various digital channels, such as search engines, social media, email, and mobile apps, businesses can connect with their target audience at a time and place that is convenient for them. This helps to increase brand awareness and boost sales. Digital marketing also allows businesses to track the success of their marketing efforts in real-time, which makes it easier to adjust and improve campaigns as needed. Overall, digital marketing is a cost-effective and efficient way for businesses to reach and engage with their customers.


        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>What Does an Digital Marketing Agency Do?</Accordion.Header>
        <Accordion.Body>
        A digital marketing agency is a company that provides a different kind of online marketing services to businesses. These services may include search engine optimization (SEO), content marketing, social media marketing, email marketing, pay-per-click (PPC) advertising, and more. The goal of a digital marketing agency is to help businesses increase their online presence and reach, and ultimately drive more sales and revenue. To do this, the agency will work with the business to understand its target audience, identify key marketing goals, and create a customized digital marketing plan. The agency will then implement the plan, track its success, and make any necessary adjustments to ensure the best possible results.


        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>What are the 3 types of digital marketing?</Accordion.Header>
        <Accordion.Body>
        There are many different types of digital marketing, but some of the most common ones include:
        <ul>
          <li>
            <strong>Search engine optimization (SEO):</strong> This is the process of improving the ranking of a website on search engines like Google, Bing, Yahoo. It involves optimizing the website's content and structure to make it more relevant and authoritative for specific keywords and phrases.
          </li>
          <li>
          <strong>Pay-per-click (PPC) advertising: </strong>This is a form of online advertising where businesses pay for per clicked or conversion. PPC advertising is typically done through platforms like Google AdWords and allows businesses to target specific keywords and demographics.
          </li>
          <li>
          <strong>Social media marketing:</strong> This involves using social media platforms, such as Facebook, Twitter, and Instagram, to build relationships with customers and promote a business's products or services.
          </li>
          <li>
          These are just a few examples of the many different types of digital marketing that are available. Other common types include content marketing, video marketing, influencer marketing, email marketing, and mobile app marketing.
          </li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
    
    </Accordion>
  );
}

export default Accordian;