import '../assets/css/header.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../assets/images/logo.png'

export const Header = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'instant'
		})
	}, [pathname]);


	const [btnActive, setBtnActive] = useState(false);
	const [serviceAct, setServiceAct] = useState(false);

	function handleClick() {
		setBtnActive(btnActive => !btnActive)


	}
	function handleClick2() {
		setServiceAct(serviceAct => !serviceAct)
	}

	if (btnActive) {
		var toggleClassActive = "is-active";
		var toggleClassMob = "mobile-nav";
	}
	else {
		var toggleClassActive = "";
		var toggleClassMob = "";


	}
	if (pathname === '/graphic') {
		var ActiveClass = "active";
	}
	else if (pathname === '/Web-design-and-development') {
		var ActiveClass = "active";
	}
	else if (pathname === '/seo') {
		var ActiveClass = "active";
	}
	else if (pathname === '/smm') {
		var ActiveClass = "active";
	}
	else if (pathname === '/ecommerce-solution') {
		var ActiveClass = "active";
	}
	else if (pathname === '/influencer-marketing') {
		var ActiveClass = "active";
	}
	else if (pathname === '/video-production') {
		var ActiveClass = "active";
	}
	else if (pathname === '/ppc') {
		var ActiveClass = "active";
	}
	else {
		var ActiveClass = "";
	}




	if (serviceAct) {
		var toggleClassserActive = "trans";

	}
	else {
		var toggleClassserActive = "";

	}



	return (
		<div>
			<header className="wow fadeIn">
				<div className="nav-wrapper">
					<div className="container">
						<nav className="navbar position-static">
							<Link to={''} > <img className="blk_log" src={Logo} alt="" title="" /></Link>

							<div onClick={handleClick} className={"menu-toggle " + toggleClassActive} id="mobile-menu">
								<span className="bar"></span>
								<span className="bar"></span>
								<span className="bar"></span>
							</div>

							<ul className={btnActive ? "nav no-search " + toggleClassMob : "nav no-search"} >
								<li>
									<Link to={''} >
										<img src={Logo} alt="Hashdigitech" title="Hashdigitech" />
									</Link>
								</li>
								<li className="nav-item"><Link className={(pathname === '/') ? 'active' : ''} to={'/'} onClick={handleClick}>HOME</Link></li>
								<li className="nav-item"><Link onClick={handleClick} className={(pathname === '/about') ? 'active' : ''} to={'/about'}>ABOUT US</Link></li>
								<li className="nav-item"><Link onClick={handleClick2} className={ActiveClass}>SERVICES<span>
									<FontAwesomeIcon icon={faCaretDown} /></span></Link>


									<div className="drop-dn">
										<ul className="service-drop">
											<li>
												<div className="drop-inner">
													<figure><img src="images/3d.png" alt="" /></figure>
													<ul className="list">
														<li>
															<h3>Digital Marketing</h3>
														</li>
														<li><Link to={'/seo'} onClick={handleClick}>Search Engine Optimization</Link></li>
														<li><Link to={'/ppc'} onClick={handleClick} > Paid Marketing</Link></li>
														<li><Link to={'/smm'} onClick={handleClick} > Social Media Marketing</Link></li>
														<li><Link to={'/ecommerce-solution'} onClick={handleClick} > Ecommerce Solution
														</Link></li>
													</ul>
												</div>
											</li>
											<li>
												<div className="drop-inner">
													<figure><img src="images/graphics.png" alt="" /></figure>
													<ul className="list">

														<li>
															<h3>Graphic Designing</h3>
														</li>
														<li><Link to={'/graphic'} onClick={handleClick}>Brochure/Catalogue</Link></li>
														<li><Link to={'/graphic'} onClick={handleClick} > Packaging Design</Link></li>
														<li><Link to={'/graphic'} onClick={handleClick} > Logo Design</Link></li>
														<li><Link to={'/graphic'} onClick={handleClick} > Print Ad</Link></li>
														<li><Link to={'/graphic'} onClick={handleClick} > EDM</Link></li>
														<li><Link to={'/graphic'} onClick={handleClick} > Social Media</Link></li>
													</ul>
												</div>
											</li>
											<li>
												<div className="drop-inner">
													<figure><img src="images/web.png" alt="" /></figure>
													<ul className="list">
														<li>
															<h3>Web Designing  &amp; <br /> Development</h3>
														</li>
														<li><Link className={(pathname === '/Web-design-and-development') ? 'active' : ''} to={'/Web-design-and-development'} onClick={handleClick} > Static Designing</Link></li>
														<li><Link className={(pathname === '/Web-design-and-development') ? 'active' : ''} to={'/Web-design-and-development'} onClick={handleClick} > Responsive Designing</Link></li>
														<li><Link className={(pathname === '/Web-design-and-development') ? 'active' : ''} to={'/Web-design-and-development'} onClick={handleClick} > Dynamic Development</Link></li>
														<li><Link className={(pathname === '/Web-design-and-development') ? 'active' : ''} to={'/Web-design-and-development'} onClick={handleClick} > Online Software</Link></li>
													</ul>
												</div>
											</li>
											<li>
												<div className="drop-inner">
													<figure><img src="images/photography.png" alt="" /></figure>
													<ul className="list">
														<li>
															<h3>Influencer Marketing</h3>
														</li>
														<li><Link to={'/influencer-marketing'} onClick={handleClick} > People</Link></li>
														<li><Link to={'/influencer-marketing'} onClick={handleClick} > Products</Link></li>
														<li><Link to={'/influencer-marketing'} onClick={handleClick} > Industrial</Link></li>
														<li><Link to={'/influencer-marketing'} onClick={handleClick} > Interiors n Architectural</Link></li>
														<li><Link to={'/influencer-marketing'} onClick={handleClick} > Food &amp; Drinks</Link></li>
														<li><Link to={'/influencer-marketing'} onClick={handleClick} > Jewellery &amp; Watches</Link></li>
													</ul>
												</div>
											</li>
											<li>
												<div className="drop-inner">
													<figure><img src="images/video.png" alt="" /></figure>
													<ul className="list">
														<li>
															<h3>Videography</h3>
														</li>
														<li><Link to={'/video-production'} onClick={handleClick} > Video Editing</Link></li>
														<li><Link to={'/video-production'} onClick={handleClick} > Corporate Video</Link></li>
														<li><Link to={'/video-production'} onClick={handleClick} > Animation Films</Link></li>
													</ul>
												</div>
											</li>

										</ul>
									</div>

								</li>

								<ul className={"mob-drop " + toggleClassserActive}>
									<li>
										<h3>Digital Marketing <Link onClick={handleClick2} className="back-opt" href="javascript:void()"><i className="fa fa-arrow-left" aria-hidden="true"></i></Link></h3>
									</li>
									<li><Link to={'/seo'} onClick={handleClick}>Search Engine Optimization</Link></li>
									<li><Link to={'/ppc'} onClick={handleClick} > Paid Marketing</Link></li>
									<li><Link to={'/smm'} onClick={handleClick} > Social Media Marketing</Link></li>
									<li><Link to={'/ecommerce-solution'} onClick={handleClick} > Ecommerce Solution
									</Link></li>

									<li>
										<h3>Graphic Designing</h3>
									</li>
									<li><Link to={'/graphic'} onClick={handleClick}>Brochure/Catalogue</Link></li>
									<li><Link to={'/graphic'} onClick={handleClick} > Packaging Design</Link></li>
									<li><Link to={'/graphic'} onClick={handleClick} > Logo Design</Link></li>
									<li><Link to={'/graphic'} onClick={handleClick} > Print Ad</Link></li>
									<li><Link to={'/graphic'} onClick={handleClick} > EDM</Link></li>
									<li><Link to={'/graphic'} onClick={handleClick} > Social Media</Link></li>

									<li>
										<h3>Web Designing &amp; Development</h3>
									</li>
									<li><Link to={'/Web-design-and-development'} onClick={handleClick} > Static Designing</Link></li>
									<li><Link to={'/Web-design-and-development'} onClick={handleClick} > Responsive Designing</Link></li>
									<li><Link to={'/Web-design-and-development'} onClick={handleClick} > Dynamic Development</Link></li>
									<li><Link to={'/Web-design-and-development'} onClick={handleClick} > Online Software</Link></li>

									<li>
										<h3>Influencer Marketing</h3>
									</li>
									<li><Link to={'/influencer-marketing'} onClick={handleClick} > People</Link></li>
									<li><Link to={'/influencer-marketing'} onClick={handleClick} > Products</Link></li>
									<li><Link to={'/influencer-marketing'} onClick={handleClick} > Industrial</Link></li>
									<li><Link to={'/influencer-marketing'} onClick={handleClick} > Interiors n Architectural</Link></li>
									<li><Link to={'/influencer-marketing'} onClick={handleClick} > Food &amp; Drinks</Link></li>
									<li><Link to={'/influencer-marketing'} onClick={handleClick} > Jewellery &amp; Watches</Link></li>

									<li>
										<h3>Videography</h3>
									</li>
									<li><Link to={'/video-production'} onClick={handleClick} > Video Editing</Link></li>
									<li><Link to={'/video-production'} onClick={handleClick} > Corporate Video</Link></li>
									<li><Link to={'/video-production'} onClick={handleClick} > Animation Films</Link></li>
								</ul>
								<li className="nav-item"><Link target={'_blank'} to={'https://portfolio.hashdigitech.com/'}>PORTFOLIO</Link></li>
								<li className="nav-item"><Link onClick={handleClick} className={(pathname === '/contact') ? 'active' : ''} to={'/contact'}>CONTACT US</Link></li>
							</ul>
						</nav>
					</div>
				</div>

			</header>
			<div className="head-margin"></div>
		</div>
	);
}