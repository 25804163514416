import logo from './logo.svg';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import { Header } from './myComponents/Header';
import { Home } from './myComponents/Home';
import { Footer } from './myComponents/Footer';
import { Contact } from './myComponents/Contact';
import { AboutUs } from './myComponents/About';
import { GraphicDesign } from './myComponents/services/GaphicDesign';
import { WebDesign } from './myComponents/services/WebDesign';
import { InfluenceMarketing } from './myComponents/services/Influence';
import { Seo } from './myComponents/services/Seo';
import { Ppc } from './myComponents/services/Ppc';
import { Smm } from './myComponents/services/Smm';
import { Ecommerce } from './myComponents/services/Ecommerce';
import { VideoProduction } from './myComponents/services/VideoProduction';

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/graphic" element={<GraphicDesign />} />
          <Route path="/react" element={<Home />} />
          <Route path="/influencer-marketing" element={<InfluenceMarketing />} />
          <Route path="/Web-design-and-development" element={<WebDesign />} />
          <Route path="/seo" element={<Seo />} />
          <Route path="/ppc" element={<Ppc />} />
          <Route path="/smm" element={<Smm />} />
          <Route path="/ecommerce-solution" element={<Ecommerce />} />
          <Route path="/video-production" element={<VideoProduction />} />

        </Routes>
        <Footer />
      </BrowserRouter>
    </>


  );
}

export default App;
