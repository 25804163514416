import React from "react";
import { Link, useLocation } from 'react-router-dom';

import BanImage from '../assets/images/banner.jpg';
import BanImageMob from '../assets/images/banner-mob.jpg';
import AbtImage from '../assets/images/about.png';
// services images
import SeoImage from '../assets/images/services/seo.png';
import PpcImage from '../assets/images/services/ppc.png';
import SmmImage from '../assets/images/services/smm.png';
import InfImage from '../assets/images/services/influence.png';
import YtbImage from '../assets/images/services/youtube.png';
import EcommImage from '../assets/images/services/ecomerce.png';
import GrapImage from '../assets/images/services/graphic.png';
import UiImage from '../assets/images/services/ui.png';
import WebImage from '../assets/images/services/web-deve.png';
import VidImage from '../assets/images/services/video.png';

// how images

import ResImage from '../assets/images/howitworks/ic-1.png';
import CreatImage from '../assets/images/howitworks/ic-2.png';
import PromImage from '../assets/images/howitworks/ic-3.png';
import AnalysImage from '../assets/images/howitworks/ic-4.png';
import OptImage from '../assets/images/howitworks/ic-5.png';
import GenImage from '../assets/images/howitworks/ic-6.png';
import BghImage from '../assets/images/howitworks/bg.png';
import whyImage from '../assets/images/why.png';

import FaqImage from '../assets/images/faq.png';

import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../assets/css/banner.css';
import '../assets/css/about.css';
import '../assets/css/services.css';
import '../assets/css/howitworks.css';
import '../assets/css/whyus.css';
import '../font-awesome.min.css';
import '../assets/css/faq.css';


import '../assets/css/responsive.css';
import '../assets/css/enquiry.css';
import Accordian from "./Accordion";


import { Form } from "./Form";

export const Home = () => {

  return (


    <div className="main-content overflow-hidden">
      {/* BANNER */}
      <section className="banner wow fadeIn">
        <img className="d-none d-lg-block" src={BanImage} alt="" />
        <img className=" d-lg-none" src={BanImageMob} alt="" />
        <div className="container">
          <div className="seo_text wow slideIntLeft">
            <h1>SEO Marketing
              <span> Service</span>
            </h1>
            <Link to={'/contact'} className="btn-co"> Request Free SEO Analysis</Link>
          </div>
        </div>
      </section>


      <section className="about wow fadeIn">
        <div className="container">
          <div className="main-d wow slideInDown">
            <div className="left">
              <img src={AbtImage} alt="" />
              
            </div>
            <div className="right">
              <h2>About us</h2>
              <p>Hash Digitech is specifically tailored to providing top-notch digital marketing services that are on
                point with leading international business habits. We'll help lift your brand by driving traffic towards
                your website and business. We are a digital marketing agency in Delhi, serving clients from all walks of
                life. We focuses on creating value for our clients through implementing modern and effective marketing
                strategies including SEO, Paid Marketing, Influencer Marketing and budget friendly Ecommerce Solutions. We
                provide you 360 degree marketing solutions to hit your desired results. We are the top performers in
                offering breathtaking digital services to all of our clients!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="services ">
        <div className="container">
          <h2>Our services</h2>
          <ul className="px-0">

            <li className="wow slideInLeft">
              <figure> <img src={SeoImage} alt="" />
              </figure>
              <h3>Search Engine Optimization</h3>
              <p>SEO is the key of successful online business. We make sure that your site is optimized for search engines
                like Google, Bing and Yahoo by utilizing our SEO services. If you want to rank higher or skyrocket your
                traffic, then you need us!

              </p>
            </li>
            <li className="wow slideInLeft">
              <figure>
                <img src={PpcImage} alt="" />
              </figure>
              <h3>PPC</h3>
              <p>You just want traffic or leads but Hash Digitech know how to do it. PPC is one of the most effective ways
                to drive traffic or leads to your website or business. Hash Digitech can help you generate millions of
                visitors who are interested in what you have to offer. We use modern strategies and high-quality PPC
                services to maximize your potential customers.</p>
            </li>
            <li className="wow slideInLeft">
              <figure>
                <img src={SmmImage} alt="" />
              </figure>
              <h3> Social Media Marketing</h3>
              <p>Social media is the most effective way to connect with your realtime audience. But without the right
                strategy in place, your efforts could be wasted. We'll help you develop a strategy that will help you
                connect with your audience, engage them, and build trust through the use of real-time content.</p>
            </li>
            <li className="wow slideInLeft">
              <figure>
                <img src={InfImage} alt="" />
              </figure>
              <h3>Influencer Marketing</h3>
              <p>We are providers of highly researched influencer marketing campaigns to reach out to your traffic nuts.
                It is the only way to raise brand awareness among your demanding customers. We are here to assist you in
                making your brand scalable and accessible with minimal effort.</p>
            </li>
            <li className="wow slideInLeft">
              <figure>
                <img src={YtbImage} alt="" />
              </figure>
              <h3>Youtube SEO</h3>
              <p>Did you know how to get a 1 billion unique page views per month? YouTube SEO is the only way to achieve
                this goal in a short period of time. It is the process of optimizing your videos title and description to
                enhance the visibility. At Hash Digitech, We are a team of professionals with years of experience, helping
                you to outrank your competitors and drive tons of traffic.</p>
            </li>
            <li className="wow slideInLeft">
              <figure>
                <img src={EcommImage} alt="" />
              </figure>
              <h3>Ecommerce Solution</h3>
              <p>Did you know how to get a 1 billion unique page views per month? YouTube SEO is the only way to achieve
                this goal in a short period of time. It is the process of optimizing your videos title and description to
                enhance the visibility. At Hash Digitech, We are a team of professionals with years of experience, helping
                you to outrank your competitors and drive tons of traffic.</p>
            </li>
            <li className="wow slideInLeft">
              <figure>
                <img src={GrapImage} alt="" />
              </figure>
              <h3>Graphic Design</h3>
              <p>Sick of tacky and disorganized graphic designs? Hash Digitech has a passion for graphic design. Our
                top-notch customer services will help you create a lasting impression on your customers. We believe in
                creating a brand that reflects your vision, rather than just being an advertisement for you. </p>
            </li>
            <li className="wow slideInLeft">
              <figure>
                <img src={UiImage} alt="" />
              </figure>
              <h3>UI Design</h3>
              <p>We strive to create a user-friendly website that will enable you to build trust with your audience and
                keep them coming back for more. We help you to lower the bounce rate of the visitor with our unique UI
                services. We know what works better for you to beat your competition.We ensure that your website is easy
                to navigate, has a clean and modern design that is easy enough for anyone to use.</p>
            </li>
            <li className="wow slideInLeft">
              <figure>
                <img src={WebImage} alt="" />
              </figure>
              <h3>Website Design & Development</h3>
              <p>Do you need a custom-built website, but don't know how to make it? We have a modern strategy to transform
                your business and elevate your brand. We'll help to make your business more noticeable through our website
                design and development services that get you on the first page of Google.We design websites that are
                eye-catching, engaging, fast loading and optimized for speed. </p>
            </li>
            <li className="wow slideInLeft">
              <figure>
                <img src={VidImage} alt="" />
              </figure>
              <h3>Video Editing</h3>
              <p>Are you a youtuber, influencer or business owner? Get the boom? No matter what your video editing needs
                are, we have the skills and experience to deliver high-quality results. We offer professional video
                editing services to help you create engaging video content for your business. Our services are perfect for
                short, high-quality and impactful videos.</p>
            </li>
          </ul>
        </div>
      </section>


      {/* How it works */}

      <section className="how_it_works">
        <div className="container">
          <h2>How it Works</h2>
          <p className="para">Hash Digitech is the area's no.1 digital marketing agency. We pledge to provide you with
            excellent digital solutions from all around the world. We help you to create an effective marketing strategy,
            website development, result driven process to touch the new heights of your business</p>

          <div className="main-d">
            <div className="left">
              <ul className="px-0">
                <li>
                  <figure>
                    <img src={ResImage} alt="" />
                  </figure>
                  <h3>Research</h3>
                  <p>Research is more than an action plan to get leverage traffic. We specialize to provide you with the
                    best research based digital solutions to get millions of organic traffic for best results.
                  </p>
                </li>
                <li>
                  <figure>
                    <img src={CreatImage} alt="" />
                  </figure>
                  <h3>Creation</h3>
                  <p>Hash Digitech helps you to create creative content to trigger engagements rates. Our team of experts
                    understands the importance of creativity in today's digital landscape.
                  </p>
                </li>
                <li>
                  <figure>
                    <img src={PromImage} alt="" />
                  </figure>
                  <h3>Promote</h3>
                  <p>Want to get results, fast? Right way of promotion work as a skyrocket for your brand. We are a
                    digital marketing agency that uses every technological advantage to promote your content in the best
                    way possible.
                  </p>
                </li>
                <li>
                  <figure>
                    <img src={AnalysImage} alt="" />
                  </figure>
                  <h3>Analysis</h3>
                  <p>Analyzing marketing campaigns is an actionable strategy to grow your business. We specialize in
                    analyzing competitors' marketing strategies, audience behavior, and ROI. With our unique research and
                    analysis methods, we're able to get you the best results from your investment in digital marketing.
                  </p>
                </li>
                <li>
                  <figure>
                    <img src={OptImage} alt="" />
                  </figure>
                  <h3>Optimize</h3>
                  <p>We're confident that our techniques will help you rock in the top pages of search engines, so you can
                    reach more people with your content!
                  </p>
                </li>
                <li>
                  <figure>
                    <img src={GenImage} alt="" />
                  </figure>
                  <h3>Generate report</h3>
                  <p>We focus on gathering data and analytics to improve your
                    marketing strategies, performance and results. A powerful report can change your way to implement
                    meaningful data, give best insights, and inspire to take action at right time.
                  </p>
                </li>
              </ul>
            </div>
            <div className="right">
              <img className="d-lg-block d-none" src={BghImage} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="why-us">
        <div className="container">
          <h2>why Hashdigitech is the best Seo Agency for your Business </h2>

          <div className="main-d">
            <ul className="wow slideInLeft px-0">

              <li>We are capable of providing all SEO ingredients at one place at an affordable price.</li>
              <li>We offer free consultations so you can understand our process from start to finish.</li>
              <li>We've built our reputation on delivering exceptional results that stand us out from the crowd.</li>
              <li>We always put our clients first and make sure they get what they need before moving on to another client.</li>
              <li>At Hashdigitech, we believe that having a strong presence online is the key to success.</li>
              <li>We use our proprietary technology to ensure that your website is optimized for search engines.</li>

            </ul>

            <div className="img "><img src={whyImage} alt="" /></div>

          </div>
        </div>
      </section>

      <section className="faq">

        <div className="container">
          <h2 className="wow slideInRight">Frequently Asked Questions</h2>
          <p className="sub-head">We’re happy to take all our client queries and answer them to the best of our knowledge </p>
          <div className="main-d">
            <div className="left acc">

              <Accordian></Accordian>
            </div>
            <div className="right">
              <figure>
                <img className="w-75" src={FaqImage} alt="" />
              </figure>
            </div>
          </div>
        </div>
      </section>


     <Form/>

    </div>
  )
}  