import { useState, useEffect } from 'react';
import TopImage from '../assets/images/contact.jpg';

import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import StaticExample from './Modal';


export const Contact = () => {


  const initiateValues = { name: "", surname: "", email: "", phone: "", message: "" }
  const [formValues, setFormValues] = useState(initiateValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    // console.log(e.target);
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    // console.log(formValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);

  };
  const allowOnlyNumericsOrDigits = (e) => {
    const charCode = String.fromCharCode(e.which);

    if (!(/[0-9]/.test(charCode))) {
      e.preventDefault();
      // document.getElementsByClassName("phoneer").innerHTML="text";

    }
  }


  useEffect(() => {
    // console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // console.log(formValues);
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i;
    const phonreg = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;


    if (!values.name) {
      errors.name = "Name is required !"
    }
    if (!values.surname) {
      errors.surname = "Surname is required !"
    }
    if (!values.email) {
      errors.email = "Email is required !"
    }
    else if (!regex.test(values.email)) {
      errors.email = "Enter valid email format !"

    }
    if (!values.phone) {
      errors.phone = "phone is required !"
    }
    else if (!phonreg.test(values.phone)) {
      errors.phone = "Enter minimum 10 digit number !"
    }

    if (!values.message) {
      errors.message = "Message is required !"
    }
    return errors;
  };
  // document.getElementById("exampleModalLive").style.display="none";
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_w8rftjq', 'template_n2uwe1q', form.current, 'L_-xZUAfV36vRMQTC')
      .then((result) => {

        document.getElementById("exampleModalLive").style.display = "block";
        document.getElementById("submit").style.display = "none";

        const myTimeout = setTimeout(myGreeting, 5000);

        document.getElementById("myId")


        function myGreeting() {
          document.getElementById("myId").innerHTML = "";
          document.getElementById("submit").style.display = "unset";
          document.getElementById("exampleModalLive").style.display = "none";

        }



      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <div className="contact-form">
      <div className="top-img position-relative">
        <img src={TopImage} alt="" />
        <h1 className="mb-3 position-absolute top-50 start-50 text-white translate-middle">Contact Us</h1>
      </div>
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <h2 className="mb-3">Get In Touch</h2>
            <form ref={form} onSubmit={sendEmail} className="needs-validations">
              <div className="row g-3">
                <div className="col-md-6">
                  <label for="name" className="form-label">Your Name</label>
                  <input type="text" className="form-control" id="name" name="name" value={formValues.name} onChange={handleChange} required />
                  <p className='text-danger'>{formErrors.name}</p>
                </div>
                <div className="col-md-6">
                  <label for="surname" className="form-label">Your Surname</label>
                  <input type="text" className="form-control" id="surname" name="surname" value={formValues.surname} onChange={handleChange} required />
                  <p className='text-danger'>{formErrors.surname}</p>
                </div>
                <div className="col-md-6">
                  <label for="email" className="form-label">Your Email</label>
                  <input type="text" className="form-control" id="email" name="email" value={formValues.email} onChange={handleChange} />
                  <p className='text-danger'>{formErrors.email}</p>
                </div>

                <div className="col-md-6">
                  <label for="phone" className="form-label">Your Phone</label>
                  <input type="text" className="form-control" id="phone" name="phone" value={formValues.phone} onChange={handleChange} onKeyPress={allowOnlyNumericsOrDigits} required />
                  <p className='text-danger'>{formErrors.phone}</p>
                </div>
                <div className="col-12">
                  <label for="message" className="form-label">Your Message</label>
                  <textarea className="form-control" id="message" name="message" rows="5" value={formValues.message} onChange={handleChange} required></textarea>
                  <p className='text-danger'>{formErrors.message}</p>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-6">
                      <button type="submit" id="submit" className="btn btn-dark w-100 fw-bold" value="Send" >Send</button>
                      <div id="myId" ></div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal fade show" id="exampleModalLive" tabindex="-1" aria-labelledby="exampleModalLiveLabel" aria-modal="true" role="dialog" >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLiveLabel">From Update</h5>
              {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div class="modal-body">
              <p>From Submitted Succesfully</p>
            </div>
            <div class="modal-footer">
              {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}