
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import StaticExample from './Modal';


export const Form = () => { 
const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_w8rftjq', 'template_n2uwe1q', form.current, 'L_-xZUAfV36vRMQTC')
      .then((result) => {
       
        // document.getElementById("myId").innerHTML="Form Submitted Succesfully";
        // document.getElementById("submit").style.display="none";
        // document.getElementById("myId").style.color="white";
        document.getElementById("exampleModalLive").style.display="block";
        document.getElementById("submit").style.display="none";
        const myTimeout = setTimeout(myGreeting, 5000);

          function myGreeting() {
            document.getElementById("myId").innerHTML = "";
            document.getElementById("submit").style.display="unset";
            document.getElementById("exampleModalLive").style.display="none";
          }
         
        
         
      }, (error) => {
          console.log(error.text);
      });
  };

  return(
    <section className="enquiry-form">
    <div className="container">
      <div className="left-text">

        <p>If you have any specific query regarding your upcoming project, please fill this form, we will get back to you within 3-4 hours. SOMETIMES BEFORE YOUR EXPACTATIONS :)</p>


        <figure>
          <img src="images//conatct.jpg" alt="" />
        </figure>
      </div>
      <div className="right-form wow slideInLeft">
        <div className="heading">
          <h2>GET IN TOUCH</h2>
          <p>distinctively exploit optimal alignments for intuitive coordinate business applications technologies</p>
        </div>
        <form ref={form} onSubmit={sendEmail} className="needs-validations">
          <input type="hidden" name="action" value="contact_form"  required/>
          <input className="name" type="text" name="name" placeholder="Name" required/>
          <input className="email" type="text" name="email" placeholder="Email" required/>
          <input className="phone" type="text" name="phone" placeholder="Phone" required/>
          <textarea name="message" id="" cols="30" rows="50" placeholder='message'></textarea> 
          <button className="btn" name="submit" type="submit" id="submit" >submit now</button>
          <div id="myId" ></div>
        </form>
      </div>
    </div>
    <div class="modal fade show" id="exampleModalLive" tabindex="-1" aria-labelledby="exampleModalLiveLabel" aria-modal="true" role="dialog" >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLiveLabel">From Update</h5>
              {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div class="modal-body">
              <p>From Submitted Succesfully</p>
            </div>
            <div class="modal-footer">
              {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button> */}
            </div>
          </div>
        </div>
      </div>
  </section>

  )
}