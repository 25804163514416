import { Link } from 'react-router-dom';
import BannerIm from '../../assets/images/details/influence-banner.jpg';
import InfIm from '../../assets/images/details/influence.jpg';
import './../../assets/css/details.css'

export const InfluenceMarketing = () => {
	return (
		<div className="web">

			<section className="top-ban position-relative">
				<img src={BannerIm} alt="" />
				<h1 className="position-absolute top-50 start-50 translate-middle text-white">Influencer Marketing</h1>
			</section>

			<section className="main-data py-5">
				<div className="container">
					<div className="main-d row">
						<div className="left-de shadow-sm p-3 mb-5 bg-body rounded col-md-8 pe-4">
							<figure>
								<img src={InfIm} alt="" />
							</figure>

							<h1 className='fs-2 fw-bold'>Influencer Marketing</h1>

							<div className="details-all">
								<p>Influencer marketing has quickly become one of the most effective and popular digital marketing strategies. By partnering with social media influencers and celebrities, businesses can reach new audiences, build brand awareness, and increase sales. At Hash Digitech, we specialize in influencer marketing, and we're here to help you develop and execute effective influencer campaigns.</p>
								<br /><br />
								<h3 className='fw-bold fs-3'>Here's what our influencer marketing services can do for your business:</h3>

								<ul className="list-style-disc ps-2 pt-2">
									<li>
										<p><strong>Strategy and Campaign Planning:</strong> We'll work with you to develop a customized influencer marketing strategy that is tailored to your business and marketing goals. We'll research your industry, target audience, and competition to identify the best influencers for your brand and determine the most effective messaging and content for your campaigns.</p>
									</li>
									<li>
									<p><strong>Influencer Identification and Outreach:</strong> We'll use our network of influencers and industry contacts to identify the best influencers for your brand. We'll reach out to these influencers and negotiate partnerships that are designed to maximize your brand's exposure and reach.</p>
									</li>
									<li>
									<p><strong>Content Creation and Management:</strong> We'll work with your influencers to create high-quality content that is designed to engage and resonate with your target audience. From social media posts and blog articles to videos and podcasts, we'll manage the entire content creation process to ensure that it aligns with your brand's messaging and values.</p>
									</li>
									<li>
									<p><strong>Campaign Monitoring and Optimization:</strong> We'll monitor your influencer campaigns in real-time to ensure that they're performing as expected. We'll analyze your campaigns' metrics and adjust your strategy and tactics as needed to ensure that you're getting the best possible results.</p>
									</li>
									<li>
									<p><strong>Reporting and Analytics: </strong>  We'll provide regular reporting on your campaigns' performance, including metrics like reach, engagement, and return on investment (ROI). We'll analyze your data to identify areas for improvement and make adjustments to your campaigns as needed.</p>
									</li>


								</ul>

								<h3 className='fw-bold fs-3'>Here are some of the benefits of working with Hash Digitech for your influencer marketing:

								</h3>
							

								<ul className='list-style-disc ps-2 pt-2'>
									<li>
									<p><strong>Increased Brand Awareness: </strong> By partnering with social media influencers and celebrities, we'll help you increase your brand's exposure and reach new audiences.</p>
									</li>
									<li>
									<p><strong>Authentic Content:	 </strong>  By working with influencers who align with your brand's values and messaging, we'll help you create authentic and engaging content that resonates with your target audience.</p>
									</li>
									<li>
									<p><strong>Increased Engagement: </strong>  By creating compelling content that encourages your audience to engage with your brand, we'll help you increase your social media engagement and grow your followers.</p>
									</li>
									<li>
									<p><strong>Higher Conversion Rates:  </strong>  By partnering with influencers who have engaged and loyal followers, we'll help you increase your conversion rates and generate more sales.</p>
									</li>
									<li>
									<p><strong>Improved ROI:  </strong>   By providing regular reporting and analysis of your campaigns' performance, we'll help you identify areas for improvement and make adjustments to your strategy to improve your return on investment (ROI).</p>
									</li>
									

								</ul>
							</div>
							<br /><br/>
							<p>At Hash Digitech, we're committed to helping businesses of all sizes succeed with influencer marketing. Whether you're just getting started with influencer marketing or looking to take your campaigns to the next level, we're here to help. Contact us today to learn more about our influencer marketing services and how we can help you achieve your digital marketing goals.</p>
						</div>
						<div className="right-de col-md-4 ps-4">
							<div className="r-box shadow-sm p-3 mb-5 bg-body rounded">
								<h2>All services</h2>
								<ul className="px-0">
									<li><Link to={'/seo'} className="fw-semibold">Social Engine Optimzation</Link></li>
									<li><Link to={'/ppc'} className="fw-semibold">Pay Per Click</Link></li>
									<li><Link to={'/smm'} className="fw-semibold">Social Media Marketing</Link></li>
									<li><Link to={'/ecommerce-solution'} className="fw-semibold">Ecommerce Solution</Link></li>
									<li><Link to={'/graphic'} className="fw-semibold">Graphic Designing</Link></li>
									<li><Link to={'/web-design-and-development'} className="fw-semibold">Web Design & Development</Link></li>
									<li><Link to={'/video-production'} className="fw-semibold">Videography</Link></li>
								</ul>
							</div>

						</div>
					</div>

				</div>
			</section>
		</div>

	)
}