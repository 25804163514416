import { Link } from 'react-router-dom';
import BannerIm from '../../assets/images/details/banner-web.jpg';
import WebIm from '../../assets/images/details/web.jpg';
import './../../assets/css/details.css'

export const WebDesign = () => {
	return (
		<div className="web">

			<section className="top-ban position-relative">
				<img src={BannerIm} alt="" />
				<h1 className="position-absolute top-50 start-50 translate-middle text-white">Web Design & Development</h1>
			</section>

			<section className="main-data py-5">
				<div className="container">
					<div className="main-d row">
						<div className="left-de shadow-sm p-3 mb-5 bg-body rounded col-md-8 pe-4">
							<figure>
								<img src={WebIm} alt="" />
							</figure>

							<h1 className='fs-2 fw-bold'>Website Design and Development</h1>

							<div className="details-all">
								<p>Welcome to Hash Digitech's Website Design and Development Services page! As a leading website design and development company, we specialize in creating custom websites that are tailored to meet our clients' specific needs. Our team of experienced web designers and developers use the latest design and development tools and techniques to create visually appealing and high-performing websites that help our clients achieve their business goals.



								</p>
								<br /><br />
								<h3 className='fw-bold fs-3'>Our Website Design and Development Services:</h3>

								<ul className="list-style-disc ps-2 pt-2">
									<li>
										<h4>Custom Website Design</h4>
										<p> At Hash Digitech, we understand that every business is unique, and so are their website design needs. Our team of experienced web designers works closely with our clients to create custom website designs that meet their specific requirements and reflect their brand's personality and values. Our custom website designs are not only visually appealing but also user-friendly and optimized for search engines.</p>
									</li>
									<li>
										<h4> Responsive Web Design</h4>
										<p> With the increasing use of mobile devices, having a responsive website design is more important than ever. At Hash Digitech, we offer responsive web design services that ensure that our clients' websites are accessible and user-friendly on all devices, including desktops, tablets, and smartphones. Our responsive web designs are not only visually appealing but also optimized for performance and search engines.</p>
									</li>
									<li>
										<h4> Website Development</h4>
										<p>At Hash Digitech, we offer website development services that ensure that our clients' websites are optimized for performance and functionality. Our team of experienced web developers uses the latest web development tools and techniques to create high-performing websites that meet our clients' business objectives. We specialize in developing websites using popular content management systems such as WordPress, Shopify, and Magento.</p>
									</li>
									<li>
										<h4> E-commerce Website Development</h4>
										<p> E-commerce websites have become essential for businesses to reach a larger audience and increase their sales. At Hash Digitech, we offer custom e-commerce website development services that are tailored to meet our clients' specific needs. Our team of experienced e-commerce developers uses the latest e-commerce development tools and techniques to create high-performing e-commerce websites that are user-friendly, visually appealing, and optimized for search engines.</p>
									</li>
									<li>
										<h4>Website Maintenance and Support</h4>
										<p>At Hash Digitech, we understand the importance of keeping our clients' websites up-to-date and secure. That's why we offer website maintenance and support services that ensure that our clients' websites are running smoothly and performing optimally. Our website maintenance and support services include regular backups, security updates, and performance optimization.</p>
									</li>


								</ul>

								<h3 className='fw-bold fs-3'>Why Choose Hash Digitech for Website Design and Development Services?
								</h3>
								<p>At Hash Digitech, we are committed to delivering high-quality website design and development services that meet our clients' needs. Here are some of the reasons why you should choose us for your website design and development needs:</p>

								<ul className='list-style-disc ps-2 pt-2'>
									<li>
										<h4> Experienced Designers and Developers</h4>
										<p>Our team of experienced web designers and developers has years of experience in creating custom websites that meet our clients' specific requirements. They are highly skilled in using the latest web design and development tools and techniques to create high-performing websites that help our clients achieve their business goals.</p>
									</li>
									<li>
										<h4> Custom Designs and Development</h4>
										<p>We understand that every business is unique, and so are their website design and development needs. Our team of web designers and developers works closely with our clients to understand their specific requirements and create custom designs and development solutions that meet their needs and reflect their brand's personality and values.</p>
									</li>
									<li>
										<h4>Affordable Prices</h4>
										<p>We believe that high-quality website design and development services should be accessible to businesses of all sizes. That's why we offer affordable prices for all our website design and development services, without compromising on the quality of our designs and development solutions.</p>
									</li>
									<li>
										<h4> Quick Turnaround</h4>
										<p>We understand the importance of delivering website designs and development solutions on time, and we strive to deliver our projects within the agreed-upon timelines. We work closely with our clients to ensure that their projects are delivered on time and to their satisfaction.</p>
									</li>

								</ul>
							</div>
						</div>
						<div className="right-de col-md-4 ps-4">
							<div className="r-box shadow-sm p-3 mb-5 bg-body rounded">
								<h2>All services</h2>
								<ul className="px-0">
									<li><Link to={'/seo'} className="fw-semibold">Social Engine Optimzation</Link></li>
									<li><Link to={'/ppc'} className="fw-semibold">Pay Per Click</Link></li>
									<li><Link to={'/smm'} className="fw-semibold">Social Media Marketing</Link></li>
									<li><Link to={'ecommerce-soution'} className="fw-semibold">Ecommerce Solution</Link></li>
									<li><Link to={'/graphic'} className="fw-semibold">Graphic Designing</Link></li>
									<li><Link to={'/influencer-marketing'} className="fw-semibold">Influencer Marketing</Link></li>
									<li><Link to={'/video-production'} className="fw-semibold">Videography</Link></li>
								</ul>
							</div>

						</div>
					</div>

				</div>
			</section>
		</div>

	)
}