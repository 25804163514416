import { Link } from 'react-router-dom';
import BannerIm from '../../assets/images/details/smm-banner.jpg';
import SmmIm from '../../assets/images/details/smm.jpg';
import './../../assets/css/details.css'

export const Smm = () => {
	return (
		<div className="web">

			<section className="top-ban position-relative">
				<img src={BannerIm} alt="" />
				<h1 className="position-absolute top-50 start-50 translate-middle text-white">Social Media Marketing</h1>
			</section>

			<section className="main-data py-5">
				<div className="container">
					<div className="main-d row">
						<div className="left-de shadow-sm p-3 mb-5 bg-body rounded col-md-8 pe-4">
							<figure>
								<img src={SmmIm} alt="" />
							</figure>

							<h1 className='fs-2 fw-bold'>Social Media Marketing </h1>

							<div className="details-all">
								<p>Social media has become an integral part of modern life, with billions of people around the world using platforms like Facebook, Twitter, Instagram, and LinkedIn to connect with friends, family, and businesses. At Hash Digitech, we understand the power of social media for businesses, and we're here to help you harness that power to achieve your marketing goals.

								</p>
								<p>Our Social Media Marketing (SMM) services are designed to help businesses of all sizes establish and grow their presence on social media platforms. With over a decade of experience in the industry, we have the knowledge and expertise necessary to help you create a strong, engaging, and effective social media strategy.
								</p>
						
								<h3 className='fw-bold fs-3'>Here are some of the ways we can help you with your social media marketing:

								</h3>
			

								<ul className="list-style-disc ps-2 pt-2">
									<li>
									<p><strong>Social Media Strategy: </strong> We'll work with you to develop a customized social media strategy that is tailored to your business and marketing goals. We'll analyze your industry, target audience, and competition to determine the best platforms and messaging for your business, and help you create a content plan that will resonate with your audience.</p>
									</li>
									<li>
										
										<p><strong>Content Creation and Management: </strong> We'll help you create and curate high-quality content that is designed to engage your audience and drive traffic to your website. From blog posts and infographics to videos and social media updates, we'll work with you to create a content plan that is both effective and efficient.

										</p>
									</li>
									<li>
										
										<p><strong>Social Media Advertising:</strong> We'll help you create and manage social media advertising campaigns that are designed to drive targeted traffic to your website and increase conversions. From paid social media ads to sponsored content, we'll help you create a social media advertising strategy that works for your business.
										</p>
									</li>
									<li>
									<p><strong>Social Media Monitoring and Reporting: </strong> We'll monitor your social media accounts and provide regular reporting on your social media performance. This includes metrics like engagement rates, follower growth, and click-through rates, as well as insights into your audience and competitors.</p>
									</li>
									<li>
										<p> <strong>Reputation Management: </strong> We'll help you manage your online reputation by monitoring and responding to customer feedback and reviews on social media. We'll work with you to create a reputation management plan and ensure that your brand is always represented in a positive light.</p>
							
									</li>
								</ul>

								<h3 className='fw-bold fs-3'>Here are some of the benefits of working with Hash Digitech for your social media marketing:


								</h3>


								<ul className='list-style-disc ps-2 pt-2'>
									<li>
										<p><strong>Increased Brand Awareness: </strong> By partnering with social media influencers and celebrities, we'll help you increase your brand's exposure and reach new audiences.</p>
									</li>
									<li>
										<p><strong>Higher Engagement Rates: </strong>  By creating and curating high-quality content, we'll help you increase engagement rates and build stronger relationships with your audience.</p>
									</li>
									<li>
										<p><strong>More Traffic and Conversions: </strong>  By creating targeted social media advertising campaigns and optimizing your content for social media, we'll help you drive more traffic to your website and increase conversions.</p>
									</li>
									<li>
										<p><strong>Improved Customer Satisfaction:  </strong> By monitoring and responding to customer feedback and reviews on social media, we'll help you improve customer satisfaction and build a stronger brand reputation</p>
									</li>
									


								</ul>
							</div>
							<br /><br />
							<p>At Hash Digitech, we're committed to helping businesses of all sizes succeed on social media. Whether you're just getting started with social media or looking to take your social media strategy to the next level, we're here to help. Contact us today to learn more about our Social Media Marketing services and how we can help you achieve your digital marketing goals.
</p>
						</div>
						<div className="right-de col-md-4 ps-4">
							<div className="r-box shadow-sm p-3 mb-5 bg-body rounded">
								<h2>All services</h2>
								<ul className="px-0">
								<li><Link to={'/seo'} className="fw-semibold">Social Engine Optimization</Link></li>
									<li><Link to={'/ppc'} className="fw-semibold">Pay Per Click</Link></li>
									<li><Link to={'/influencer-marketing'} className="fw-semibold">Influencer Marketing</Link></li>
									<li><Link to={'/ecommerce-solution'} className="fw-semibold">Ecommerce Solution</Link></li>
									<li><Link to={'/graphic'} className="fw-semibold">Graphic Designing</Link></li>
									<li><Link to={'/web-design-and-development'} className="fw-semibold">Web Design & Development</Link></li>
									<li><Link to={'/video-production'} className="fw-semibold">Videography</Link></li>
								</ul>
							</div>

						</div>
					</div>

				</div>
			</section>
		</div>

	)
}