import { Link } from 'react-router-dom';
import BannerIm from '../../assets/images/details/video-banner.jpg';
import VideoIm from '../../assets/images/details/video.jpg';
import './../../assets/css/details.css'

export const VideoProduction = () => {
	return (
		<div className="web">

			<section className="top-ban position-relative">
				<img src={BannerIm} alt="" />
				<h1 className="position-absolute top-50 start-50 translate-middle text-white">Video Production</h1>
			</section>

			<section className="main-data py-5">
				<div className="container">
					<div className="main-d row">
						<div className="left-de shadow-sm p-3 mb-5 bg-body rounded col-md-8 pe-4">
							<figure>
								<img src={VideoIm} alt="" />
							</figure>

							<h2 className='fs-2 fw-bold'>Video Production</h2>

							<div className="details-all">
								<p>Another important aspect of SEO is on-page optimization. This includes optimizing the content on your website to ensure that it is easy for search engines to understand and index. We'll work with you to identify opportunities to improve your website's structure, meta data, and content, making it more likely to show up in relevant searches.


								</p>


								<h3 className='fw-bold fs-3'>On-Page Optimization: Optimizing Your Website for Search Engines and Users
								</h3>
								<p>Another important aspect of SEO is on-page optimization. This includes optimizing the content on your website to ensure that it is easy for search engines to understand and index. We'll work with you to identify opportunities to improve your website's structure, meta data, and content, making it more likely to show up in relevant searches.

								</p>

								<ul className="list-style-disc ps-2 pt-2">
									<li>
										<h4>Corporate Videos
										</h4>
										<p>We specialize in creating corporate videos that help businesses communicate their brand message, showcase their products and services, and highlight their achievements. Our corporate video production services include scripting, pre-production planning, filming, and post-production editing to ensure that our clients' video content is professional, engaging, and on-brand.

										</p>
									</li>
									<li>
										<h4>Product Videos</h4>
										<p>Product videos are an excellent way to showcase the benefits and features of your products to potential customers. Our product video production services include scripting, filming, and post-production editing to ensure that our clients' product videos are engaging and visually appealing. We work closely with our clients to understand their products and create videos that highlight their unique features and benefits.


										</p>
									</li>
									<li>
										<h4>Testimonial Videos
										</h4>
										<p>Testimonial videos are an effective way to build trust and credibility with potential customers. Our testimonial video production services include scripting, filming, and post-production editing to ensure that our clients' testimonial videos are authentic and compelling. We work closely with our clients to identify their satisfied customers and create videos that showcase their positive experiences.
										</p>
									</li>
									<li>
										<h4>Animated Videos</h4>
										<p>Animated videos are a fun and engaging way to convey complex information in an easy-to-understand format. Our animated video production services include scripting, storyboarding, voiceover recording, animation, and post-production editing to ensure that our clients' animated videos are visually appealing and informative. We work closely with our clients to understand their message and create animated videos that deliver it effectively.

										</p>
									</li>
									<li>
										<h4>Live Event Videos
										</h4>
										<p>Live event videos are an excellent way to capture the highlights of an event and create promotional content for future events. Our live event video production services include filming and post-production editing to ensure that our clients' live event videos are engaging and visually appealing. We work closely with our clients to understand their event and create videos that showcase the highlights and create excitement for future events.
										</p>

									</li>
								</ul>

								<h3 className='fw-bold fs-3'>Why Choose Hash Digitech for Video Production Services?
								</h3>
								<p>At Hash Digitech, we are committed to delivering high-quality video production services that help our clients achieve their business goals. Here are some of the reasons why you should choose us for your video production needs:
									<br />
									Another important aspect of SEO is on-page optimization. This includes optimizing the content on your website to ensure that it is easy for search engines to understand and index. We'll work with you to identify opportunities to improve your website's structure, meta data, and content, making it more likely to show up in relevant searches.

								</p>

								<ul className="list-style-disc ps-2 pt-2">
									<li>
										<h4>Experienced Videographers, Producers, and Editors
										</h4>
										<p>Our team of experienced videographers, producers, and editors has years of experience in creating professional video content for businesses of all sizes. They are highly skilled in using the latest video production tools and techniques to create high-quality video content that delivers results.

										</p>
									</li>

								</ul>
							</div>

						</div>
						<div className="right-de col-md-4 ps-4">
							<div className="r-box shadow-sm p-3 mb-5 bg-body rounded">
								<h2>All services</h2>
								<ul className="px-0">
									<li><Link to={'/seo'} className="fw-semibold">Social Engine Optimization</Link></li>
									<li><Link to={'/ppc'} className="fw-semibold">Pay Per Click</Link></li>
									<li><Link to={'/smm'} className="fw-semibold">Social Media Marketing</Link></li>
									<li><Link to={'/ecommerce-solution'} className="fw-semibold">Ecommerce Solution</Link></li>
									<li><Link to={'/influencer-marketing'} className="fw-semibold">Influencer Marketing</Link></li>
									<li><Link to={'/graphic'} className="fw-semibold">Graphic Designing</Link></li>
									<li><Link to={'/web-design-and-development'} className="fw-semibold">Web Design & Development</Link></li>

								</ul>
							</div>

						</div>
					</div>

				</div>
			</section>
		</div>

	)
}